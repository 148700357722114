import React from "react"
import GoBackArrow from "../../components/GoBackArrow"
import { Link } from "gatsby"

export default function main() {
  return (
    <div className="live-streaming-page">
      <GoBackArrow />
      <div className="secondary-pink-block"></div>
      <div className="page-wrapper">
        <h1 className="description-header">LIVE STREAMING</h1>
        <p className="description">
          Whether you're serenading the world with your songs, bending over
          backwards to do camel pose with your class , we can help to give you
          the best quality for your streams
          <br />
          <br />
          <span style={{ textDecoration: "underline" }}>
            <a
              href="https://www.youtube.com/watch?v=I89gKHFE3Ws"
              target="_blank"
            >
              WATCH THE VIDEO
            </a>
          </span>
        </p>
        <div className="blocks">
          <div className="blocks-row">
            <div className="block-1-a">
              <span
                className="star-symbol"
                style={{ fontSize: "150px", color: "#FFFFFF" }}
              >
                *
              </span>
            </div>
            <div className="block-1-b">
              <span style={{ fontWeight: "bold" }}>FREE HOUR</span> <br />
              <span> tech support!</span>
              <p>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeeYGQb4WdqnNQ95TX8dUacxpSd95stdrUjxWUNp-0nomN6lA/viewform?usp=embed_facebook"
                  target="_blank"
                >
                  Sign up here
                </a>{" "}
              </p>
            </div>
          </div>
          <div className="blocks-row">
            <div className="block-2-a">
              <span style={{ fontSize: "150px", color: "#FFFFFF" }}>*</span>
            </div>
            <div className="block-2-b">
              <span style={{ fontWeight: "bold" }}>THINKING BIG? </span> <br />
              <p>
                {" "}
                <a
                  href="mailto:tracks@smileysound.com?Subject=Hey Smiley Sound! I'm interested"
                  target="_top"
                >
                  Contact us{" "}
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Link className="faq-link" to="/livestreaming/faq">
        FAQ AND DOWNLOADS
      </Link>
    </div>
  )
}
